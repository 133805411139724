<template lang="html">
  <div class="">

    <!-- <div  v-if="this.w <=450" :style="{width:'100%', height:'66px'}">
      <Navigation/>
    </div> -->

    <div  :style="{width:'100%'}">
     <div  :style="{width:'100%', height:'360px',paddingBottom:'30px',backgroundColor:'white', color:'#0A6338'}">
      <div :style="{fontSize:'29px',lineHeight:'32px', textAlign:'center',width:'100%' ,color:'#0A6338',fontWeight:'500',height:'auto' ,marginTop:'0%', paddingTop:'2%'}">
        Hitta reparatören du behöver
      </div>
      <div :style="{display:'flex'}">
        <h1   :style="{lineHeight:'18px', width:'85%',marginLeft:'6.5%',textAlign:'center',fontSize:'16px' ,marginTop:'3%', letterSpacing: '0px'   }">
          <span :style="{fontWeight:'bold'}" >
            Vi har reparatörer &nbsp;
          </span>
          <h2 :style="{fontSize:'16px'}">
            i hela landet, av alla slag, som kan laga dina sake
          </h2>
        </h1>
      </div>

      <Search/>
      <div :style="{height:'auto',width:'100%',marginTop:'14%',backgroundColor:'white'}">
        <div :style="{height:'auto'}" >
          <div :style="{textDecoration:'underline', textAlign:'center',fontSize:'25px', fontWeight:500}">
            Våra tjänster
          </div>
          <div :style="{marginLeft:'1%', marginTop:'6%'}">
            <div v-for="(service,id) in service_list" :key="id" :style="{borderRadius: '4px',
                   marginLeft:'2%',width:'96%',background: '#FFF',marginTop:'6%',padding:'5px' ,
                   paddingBottom:'8px' }">
                <div :style="{width: '100px', height: '100px', marginLeft:'35%'}">
                  <b-icon v-if="service.id ==1 " icon="geo-alt-fill" :style="{height:'61px', marginTop:'20%',color:'#0A6338',width:'61px'}"></b-icon>
                  <b-icon v-if="service.id ==2 " icon="search" :style="{height:'61px', marginTop:'20%',color:'#0A6338',width:'61px'}"></b-icon>
                  <b-icon v-if="service.id ==3 " icon="tools" :style="{height:'61px', marginTop:'20%',color:'#0A6338',width:'61px'}"></b-icon>
                </div>

                <div :style="{borderRadius: '4px',width:'100%', fontSize: '18px', color: '#264653',fontWeight:600}">
                  {{service.service_header}}
                </div>
                <div :style="{borderRadius: '4px',width:'95%', fontSize: '16px',fontWeight: 400,marginLeft:'2%', color:'black'}">
                  {{service.service}}
                </div>
            </div>
          </div>
        </div>

      </div>
    </div>
   </div>
     <!-- <bottombar/> -->
  </div>
</template>

<script>
 import Search from '../components/search.vue';
//  import Navigation from '../components/Navigation.vue';
// import bottombar from '../components/bottombar.vue';
export default {
  data:function(){
    return{
      service_list:[]
    }
  },

  mounted(){
    this.w = window.screen.width
    this.service_list=[
      {'id':1, 'service_header':'VÄLJ DET LÄN DU BOR I',  'service':'Hitta professionella reparatörer i ditt område genom att välja det län där du bor.','img':'repariflat.jpg' },
      {'id':2, 'service_header':'SÖK DET DU BEHÖVER LAGA',  'service':'Sök efter det du behöver laga på vår plattform. Vi erbjuder en omfattande lista över reparationstjänster som täcker allt från elektronik till skrädderi.','img':'repariflat.jpg' },
      {'id':3, 'service_header':'HITTA REPARATÖR',  'service':'Vårt nätverk av erfarna och skickliga reparatörer finns tillgängliga för att ta itu med dina problem.','img':'repariflat.jpg' }
    ]

  },
  components:{
    Search,
    //Navigation,
    // bottombar
  }
}
</script>

<style lang="css" scoped>
</style>
